<template>
  <div class="communityCanteenOrder">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      :isOperateColumn="false"
    >
      <template #headBtnSlot>
        <v-button text="导出" @click="exportFile"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          label="订餐人姓名："
          v-model="searchParam.receiverName"
          placeholder="请输入订餐人姓名"
        ></v-input>
        <v-input
          label="联系方式："
          type="number"
          v-model="searchParam.receiverPhone"
          placeholder="请输入联系方式"
        ></v-input>
        <v-date-picker
          label="下单时间："
          v-model="time"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="开始时间"
          endPlaceholder="结束时间"
        />
        <v-select
          label="类别："
          clearable
          placeholder="请选择订单状态"
          :options="foodTimeOps"
          v-model="searchParam.foodTime"
        />
        <v-select
          label="订单状态："
          clearable
          placeholder="请选择订单状态"
          :options="orderStatusOps"
          v-model="searchParam.orderStatus"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { getFoodOrderByOldId, exportFoodOrder } from "./api.js";
import {
  orderStatusMap,
  orderStatusOps,
  foodTimeOps,
  foodTimeMap,
} from "./map.js";
export default {
  name: "communityCanteenOrder",
  data() {
    return {
      orderStatusOps: orderStatusOps(),
      foodTimeOps: foodTimeOps(),
      name: "",
      tableUrl: getFoodOrderByOldId,
      searchParam: {
        receiverName: "", //联系人
        receiverPhone: "", //联系电话
        foodTime: "",
        beginTime: "",
        endTime: "",
        orderStatus: "",
      },
      headers: [
        {
          prop: "receiverName",
          label: "订餐人姓名",
        },
        {
          prop: "receiverPhone",
          label: "联系方式",
        },
        {
          prop: "countPrice",
          label: "订单金额（元）",
        },
        {
          prop: "foodTime",
          label: "类别",
          formatter: (row, prop) => {
            return foodTimeMap[row[prop]] || "--";
          },
        },
        {
          prop: "foodNames",
          label: "菜品",
        },
        {
          prop: "insertTime",
          label: "下单时间",
        },
        {
          prop: "orderStatus",
          label: "状态",
          formatter: (row, prop) => {
            return orderStatusMap[row[prop]] || "--";
          },
        },
      ],
    };
  },
  created() {
  },
  computed: {
    time: {
      get() {
        return !this.searchParam.beginTime && !this.searchParam.endTime
          ? []
          : [this.searchParam.beginTime, this.searchParam.endTime];
      },
      set(val) {
        [this.searchParam.beginTime, this.searchParam.endTime] = val || [
          "",
          "",
        ];
      },
    },
  },
  methods: {
    // 导出
    exportFile() {
      this.$axios
        .post(exportFoodOrder, this.searchParam, {
          responseType: "blob",
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          console.log(res, "res-----");
          // 得到请求到的数据后，对数据进行处理
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;charset=utf-8",
          }); // 创建一个类文件对象：Blob对象表示一个不可变的、原始数据的类文件对象
          console.log(res.headers, "res.headers");
          let fileName = decodeURI(res.headers["content-disposition"]); // 设置文件名称,decodeURI：可以对后端使用encodeURI() 函数编码过的 URI 进行解码。encodeURI() 是后端为了解决中文乱码问题
          if (fileName) {
            // 根据后端返回的数据处理文件名称
            fileName = fileName.substring(fileName.indexOf("=") + 1);
          }
          console.log(fileName, "fileName");
          const link = document.createElement("a"); // 创建一个a标签
          link.download = fileName; // 设置a标签的下载属性
          link.style.display = "none"; // 将a标签设置为隐藏
          link.href = URL.createObjectURL(blob); // 把之前处理好的地址赋给a标签的href
          document.body.appendChild(link); // 将a标签添加到body中
          link.click(); // 执行a标签的点击方法
          URL.revokeObjectURL(link.href); // 下载完成释放URL 对象
          document.body.removeChild(link); // 移除a标签
        });
    },
  },
};
</script>

<style scoped lang="less">
.communityCanteenOrder {
  box-sizing: border-box;
  height: 100%;
}
</style>
